<template>
<div>
    <div class="wrap">
        <form name="mfrm" @submit.prevent="getExpensesList" method="post">
            <div class="con_top">
                <h2><span>■</span> B2B 지출관리</h2>
                <div class="btns mb-30">
                    <a class="btn_new2 pointer" @click="register()">B2B 지출등록</a>
                </div>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                            <select v-model="idxCrmCenter" @change="getExpensesList()" class="font-16 w-120px h-40px pl-10">
                                <option value="-1">센터선택</option>
                                <option value="0">본원센터</option>
                                <option v-for="(item, index) of centerList" :key="index" :value="item.idx">{{item.name}}</option>
                            </select>

                            <input type="text" @keyup.enter="getExpensesList()" v-model="value" class="ml-20 w-200px h-40px pd-00 pl-20" placeholder="기업명">
                            <a @click="getExpensesList()" class="btn_search ml-10 pointer">Search<span></span></a>
                        </div>

                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div>기간검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <a class="btn_search pointer" @click="getExpensesList()">Search<span></span></a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>지출날짜</th>
                                <th>기업명</th>
                                <th>지출내용</th>
                                <th>센터</th>
                                <th>결제금액</th>
                                <th>관리</th>
                            </tr>
                            <tr>
                                <td colspan="7">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>지출날짜</th>
                                <th>기업명</th>
                                <th>지출내용</th>
                                <th>센터</th>
                                <th>결제금액</th>
                                <!-- <th>관리</th> -->
                            </tr>
                            <tr v-for="(item, index) of expList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td>{{item.expensesDate.slice(0,10)}}</td>
                                <td>{{item.expensesCompanyName}}</td>
                                <td>{{item.expText}}</td>
                                <td>{{item.centerName}}</td>
                                <td class="underline pointer" @click="modify(item.idx)">{{numberWithCommas(item.expPrice)}} 원</td>
                                <!-- <td><span class="underline pointer" @click="modify(item.idx)">수정</span> | <span class="underline pointer" @click="remove(item.idx)">삭제</span></td> -->
                            </tr>
                            <tr v-if="expList.length === 0">
                                <td colspan="6">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜
        idxCrmCenter: -1,
        type: 'B2B',
        value: '',

        centerList: [],
        expList: [],

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
    }),

    mounted() {
        /* this.startDate = this.formatDate(new Date())
        this.endDate = this.formatDate(new Date()) */

        if(sessionStorage.getItem('origin_expendb2b_pageNum') !== 'undefined' && sessionStorage.getItem('origin_expendb2b_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('origin_expendb2b_pageNum'))
        }
        if(sessionStorage.getItem('origin_expendb2b_value') !== 'undefined' && sessionStorage.getItem('origin_expendb2b_value') !== null) {
            this.value = sessionStorage.getItem('origin_expendb2b_value')
        }
        if(sessionStorage.getItem('origin_expendb2b_center') !== 'undefined' && sessionStorage.getItem('origin_expendb2b_center') !== null) {
            this.idxCrmCenter = sessionStorage.getItem('origin_expendb2b_center')
        }
        if(sessionStorage.getItem('origin_expendb2b_startDate') !== 'undefined' && sessionStorage.getItem('origin_expendb2b_startDate') !== null) {
            this.startDate = sessionStorage.getItem('origin_expendb2b_startDate')
        }
        if(sessionStorage.getItem('origin_expendb2b_endDate') !== 'undefined' && sessionStorage.getItem('origin_expendb2b_endDate') !== null) {
            this.endDate = sessionStorage.getItem('origin_expendb2b_endDate')
        }

        /* this.startDate = ''
        this.endDate = '' */
        this.getCenterListAll()
        this.getExpensesList()

        //this.startDate = sessionStorage.getItem('origin_expendb2b_startDate')
        //this.endDate = sessionStorage.getItem('origin_expendb2b_endDate')
    },

    methods: {

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else if (date === '') {
                return ''
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        getExpensesList() {

            let params = {
                startDate: this.startDate,
                endDate: this.endDate,
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                type: this.type,
                value: this.value,
                idxCrmCenter: this.idxCrmCenter
            }

            sessionStorage.setItem('origin_expendb2b_pageNum', this.pageNum)
            sessionStorage.setItem('origin_expendb2b_value', this.value)
            sessionStorage.setItem('origin_expendb2b_center', this.idxCrmCenter)
            sessionStorage.setItem('origin_expendb2b_startDate', this.formatDate(this.startDate))
            sessionStorage.setItem('origin_expendb2b_endDate', this.formatDate(this.endDate))

            this.loading = true;

            this.axios.get('/api/v1/user/expenses/list', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.expList = res.data.expList

                        if (res.data.expListCount) {
                            this.listLength = res.data.expListCount
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        this.expList = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
                .finally(()=>{
                    this.loading = false;
                });
        },

        getCenterListAll() {

            this.axios.get('/api/v1/center/all', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 지출등록 페이지로 이동
        register() {
            this.$router.push('/origin/sales_manage_register_b2b')
        },

        // 지출수정 페이지로 이동
        modify(idx) {
            this.$router.push(`/origin/sales_manage_modify_b2b?idx=${idx}`)
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getExpensesList()
            }
        },

        paging(index) {
            this.pageNum = index
            this.getExpensesList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getExpensesList()
            }
        },

        
    }
}
</script>
